<script setup>
import { Head, usePage } from '@inertiajs/vue3'

defineProps({
    title: {
        type: String,
        default: null
    }
})

const user = computed(() => usePage().props.auth.user)
let appName = import.meta.env.VITE_APP_NAME

let whatsCompanyFavicon = ref(false)

const updateFavicon = (showFavicon) => {
    const existingLink = document.querySelector("link[rel~='icon']")
    if (existingLink) {
        existingLink.parentNode.removeChild(existingLink)
    }

    if (showFavicon) {
        const newLink = document.createElement('link')
        newLink.rel = 'icon'
        newLink.href = '/images/favicon.ico?v=' + new Date().getTime() // Adiciona uma query string para evitar cache
        document.head.appendChild(newLink)
    }
}

onBeforeMount(() => {
    if (user.value) {
        if (user.value.role == 'agency') {
            appName = user.value.company_name
        } else if (user.value.agency_owners.length > 0) {
            //se for alguem que é filho de agencia no entanto, usa a da agencia dela
            const last = user.value.agency_owners.length - 1
            const owner = user.value.agency_owners[last]

            appName = owner.company_name
        } else {
            whatsCompanyFavicon.value = true
        }
    }
})

watch(whatsCompanyFavicon, (newValue) => {
    updateFavicon(newValue)
})
</script>

<template>
    <Head>
        <title>{{appName}} - {{ title }}</title>
        <!---
            <link 
                href="/images/favicon.ico" 
                v-if="whatsCompanyFavicon"
                rel="icon"
            >
        -->
        <slot />
    </Head>
</template>